$('[data-animate]').each(function(){
	let self = this;
	let offset = $(this).data('offset') || 100;

	if(!window.dataAnimated) {
		window.dataAnimated = false;
	}

	function verify(self) {
		if($(self).has('data-animate')) {
			if(self.getBoundingClientRect().top <= (window.innerHeight - offset)) {
				let animation = $(self).data('animate');

				$(self).addClass(animation);
				$(self).removeAttr('data-animate');
			}
		}
	}

	verify(self);

	$(window).scroll(function(){
		if(window.dataAnimated) return;

		setTimeout(function(){
			window.dataAnimated = false;
		}, 100);

		verify(self);
	});
});