$(document).click(function() {
	$('.telefone').removeClass('show');
});

$('.telefone').click(function(e) {
	e.stopPropagation()
});

$('[data-toggle="telefones"]').click(function(e){
	e.preventDefault();

	$('.telefone').toggleClass('show');
});