(function() {
    let _STATE = createProxy();
    const $popup    = document.querySelector('.message__cookies');
    const $btn      = document.querySelector('.message__cookies .btn[data-toggle="termos"]');
    const hoje      = _HOJE();
    const showClass = 'message__cookies--shown';
    const hideClass = 'message__cookies--hide';

    if($popup && $btn) {
        // Verificando se existe algum cookie resgistrado no localStorage
        if(_STATE.cookies) {

            // Verificando se a data de quando aceito é igual ao dia de Hoje
            if(_STATE.cookies.data === hoje) {

                // Removendo o popup caso a data seja igual ao dia de hoje
                $popup.parentNode.removeChild($popup);
            }else {

                // Exibindo a mensagem caso a data seja diferente
                showCookies()
            }
        }else {

            // Exibindo a mensagem caso o registro não exista   
            showCookies();
        }
    }

    function showCookies() {

        $popup.classList.add(showClass);
        
        $btn.addEventListener('click', e => {
            e.preventDefault();

            _STATE.cookies = {
                data   : hoje,
                aceito : new Date()
            };

            $popup.addEventListener('animationend', () => {
                $popup.parentNode.removeChild($popup);
            });

            $popup.classList.add(hideClass);
        });
    }

    function _HOJE() {
        let data = new Date();
        let dia  = data.getDate();
        let mes  = data.getMonth() + 1;
        let ano  = data.getFullYear();
        
        dia = dia < 10 ? `0${dia}` : dia;
        mes = mes < 10 ? `0${mes}` : mes;

        return `${dia}/${mes}/${ano}`;
    }

    function createProxy() {
        let _PROXY = {};

        Object.defineProperty(_PROXY, 'cookies', {
            get() {
                let cookies = localStorage.getItem('cookies');

                if(cookies) return JSON.parse(cookies);

                return cookies;
            },
            set(data) {
                localStorage.setItem('cookies', JSON.stringify(data));
            }
        });

        return _PROXY;
    }
})();